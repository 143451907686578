<template>
  <div class="content">
    <div class="titleStatus">
      <div class="orderPrice">
        <p>总额 ¥ {{ orderInfo.totalPrice }}</p>
        <p>订单号：{{ orderInfo.orderNo }}</p>
      </div>
      <div class="orderStatus">{{ orderInfo.statusText }}</div>
    </div>

    <div class="orderInfo">
      <div class="tickedTimeDetail">
        <div class="AviationInfo">
          <!--                 rtFlagText 0-单程  1-去程   2-返程   偶尔会出现中文接口未返回现象，用字段判断-->
          <!--                    <p v-if="orderInfo.rtFlag === 0"><span>{{orderInfo.rtFlagText}}</span></p>-->
          <p v-if="orderInfo.rtFlag === 0"><span>单程</span></p>
          <p v-else-if="orderInfo.rtFlag === 1"><span>去程</span></p>
          <p v-else-if="orderInfo.rtFlag === 2"><span>返程</span></p>
          <img :src="orderInfo.logo" alt="" style="margin-left: 17px;height: 20px">
          <p>{{ orderInfo.airCompanyName }}{{ orderInfo.flightNo }}</p>
          <p>{{ orderInfo.detTimeMd }}</p>
          <p>{{ orderInfo.depTimeW }}</p>
        </div>
        <div class="TimeInfo">
          <div class="stareTimeInfo">
            <p>{{ orderInfo.depTime }}</p>
            <p>{{ orderInfo.depAirportName }}</p>
          </div>
          <div class="TimeImg">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
              alt=""
            >
          </div>
          <div class="endTimeInfo">
            <p>{{ orderInfo.arrTime }}</p>
            <p>{{ orderInfo.arrAirportName }}</p>
          </div>
        </div>
      </div>
      <div class="van-hairline--bottom" />
      <div class="tipsWrap" @click="lookTips">
        <p>产品说明、行李规定、退改签说明相关政策</p>
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
          alt=""
        >
      </div>
    </div>
    <div class="orderPerson">
      <div class="personTitle">乘机人信息</div>
      <div class="personInfo">
        <div class="personInfoWrap">
          <p class="personInfoTitle">出行人</p>
          <div class="personInfoList">
            <div v-for="(item,index) in OrderPersonList" :key="index" class="">
              <p>{{ item.name }}</p>
              <p>{{ item.idCardText }}：{{ item.idCard }}</p>
              <p>票号：{{ item.ticketNo }}</p>
            </div>
          </div>
        </div>
        <div class="personInfoMobile">
          <p class="personInfoTitle">联系手机</p>
          <p class="personInfoMobiles">{{ orderInfoMobile }}</p>
        </div>
      </div>
      <div class="personVoucher">
        <p>报销凭证：</p>
        <p>可在机场开具行程单。<br>如需邮寄行程单，需要额外支付快递费</p>
      </div>
    </div>
    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <van-tabs v-if="VotePriceInfo" v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ VotePriceInfo.adt.ticketPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ VotePriceInfo.adt.CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="VotePriceInfo.chd.length > 0" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ VotePriceInfo.chd.chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ VotePriceInfo.CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ VotePriceInfo.adt.luggage.consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ VotePriceInfo.adt.luggage.portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ VotePriceInfo.adt.luggage.volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="VotePriceInfo.chd.length > 0" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ VotePriceInfo.chd.luggage.consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ VotePriceInfo.chd.luggage.portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ VotePriceInfo.chd.luggage.volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.adt.refundCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.chd.refundCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.adt.rerouteCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.chd.rerouteCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfos">
              <p style="width: 100%">成人{{ VotePriceInfo.adt.transferRule }}</p>
              <p style="width: 100%">儿童{{ VotePriceInfo.chd.transferRule }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  List,
  Loading,
  Sidebar,
  SidebarItem,
  Tab,
  Tabs,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)

import {
  getVideoOrderDetail,
  getProductDes
} from '@/services/comeOn.js'

export default {
  name: 'TicketOrderDetail',
  data() {
    return {
      orderNo: '', // 1010980058019
      orderInfo: {}, // 机票信息
      orderInfoMobile: '', // 联系人手机号
      OrderPersonList: [],
      showDisplayActive: false, // 展示说明
      DisplayActive: 0,
      VotePriceInfo: null, // 票价明细
      RefundsNoteInfo: {}// 退改签说明
    }
  },
  created() {

  },
  mounted() {
    this.orderNo = this.$route.query ? this.$route.query.orderNo : ''
    this.getOrderdetail()// get订单详情
    // this.lookTips();//查看说明
  },
  methods: {
    // get订单详情
    getOrderdetail() {
      getVideoOrderDetail({
        isSubNo: 1,
        orderNo: this.orderNo
      }).then(res => {
        if (Number(res.code) === 200) {
          this.orderInfo = res.data
          this.OrderPersonList = res.data.passengerList// 乘机人
          this.orderInfoMobile = res.data.contactPhone// 订单联系人
        }
      })
    },
    // 查看说明
    lookTips() {
      getProductDes({
        flightInfoId: this.orderInfo.flightInfoId,
        tripType: 'OW', // 单程还是往返
        priceInfoId: this.orderInfo.priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.$nextTick(() => {
            this.VotePriceInfo = res.data// 展示说明
            this.showDisplayActive = true// 显示产品说明
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;
  font-family: 'PingFang SC Medium';

  .titleStatus {
    width: 100%;
    height: 90px;
    background-color: #E33F44;
    padding: 30px 26px 15px;
    display: flex;
    color: #ffffff;

    .orderPrice {
      p:nth-child(1) {
        font-size: 17px;

      }

      p:nth-child(2) {
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .orderStatus {
      margin-left: auto;
      font-size: 23px;
    }
  }

  .orderInfo {
    /*行程详情*/

    .tickedTimeDetail {
      padding: 29px 15px;
      background-color: #fff;
      /*margin-top: 43px;*/
      position: relative;

      .AviationInfo {
        display: flex;

        p {
          line-height: 17px;
          font-size: 13px;
          color: #333333;
        }

        p:nth-child(1) {
          width: 32px;
          height: 17px;
          text-align: center;
          background-color: #E33F44;
          color: #ffffff;
          border-radius: 3px;

          span {
            display: inline-block;
            transform: scale(0.8);
          }
        }

        p:nth-child(2) {
          margin-left: 28px;
        }

        p:nth-child(3) {
          margin-left: 10px;
        }

        p:nth-child(4) {
          margin-left: 10px;
        }
      }

      .TimeInfo {
        display: flex;
        margin-top: 20px;
        width: 250px;
        position: relative;
        left: 50%;
        margin-left: -125px;

        .stareTimeInfo, .endTimeInfo {
          color: #333333;
          text-align: center;

          p:nth-child(1) {
            font-size: 29px;
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 12px;
          }
        }

        .TimeImg {
          img {
            width: 52px;
            height: 6px;
            margin: 14px 28px;
          }
        }
      }
    }

    .tipsWrap {
      font-size: 13px;
      color: #333333;
      display: flex;
      padding: 13px 14px;
      background-color: #fff;

      img {
        margin-left: 6px;
        margin-top: 2px;
        width: 7px;
        height: 11px;
      }
    }
  }

  .orderPerson {
    margin-top: 7px;
    background-color: #fff;
    padding: 17px 14px;

    .personTitle {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
    }

    .personInfo {
      margin-top: 26px;

      .personInfoWrap, .personInfoMobile {
        .personInfoTitle, .personInfoMobile {
          font-size: 14px;
          color: #666666;
          width: 62px;
        }

        .personInfoList, .personInfoMobiles {
          margin-left: 16px;
          font-size: 14px;
          color: #000000;
          font-weight: 500;

          p {
            margin-bottom: 13px;
          }

          div {
            margin-bottom: 13px;
          }
        }
      }

      .personInfoWrap, .personInfoMobile {
        display: flex;

      }

      .personInfoMobile {
        margin-top: 15px;
      }
    }

    .personVoucher {
      display: flex;
      margin-top: 16px;
      color: #E33F44;
      font-size: 12px;
    }
  }

  .DisplayActiveWrap {
    padding: 16px 20px;

    .DisplayActiveTab {
      padding-top: 16px;
    }

    .TicketTitle {
      display: flex;

      img {
        width: 13px;
        height: 13px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        margin-left: 10px;
      }
    }

    .TicketContent {
      width: 100%;
      margin-top: 11px;
      border-radius: 3px;
      border: 1px solid #E8E8E8;
      text-align: left;

      .TicketContentTitle {
        width: 100%;
        border-radius: 3px 3px 0 0;
        background-color: #f8f8f8;
        font-size: 13px;
        color: #333333;
        padding: 13px 14px;
        font-weight: 500;
      }

      .TicketContentInfo {
        width: 100%;
        display: flex;

        p {
          font-size: 13px;
          padding-left: 14px;
          height: 40px;
          line-height: 40px;
        }

        p:nth-child(1) {
          width: 53px;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(3), p:nth-child(5) {
          color: #666666;
        }

        p:nth-child(3) {
          width: 155px;
        }

        p:nth-child(5) {
          width: 104px;
        }
      }

      /*行李info*/

      .TicketBaggageContentInfo {
        /*display: flex;*/

        .chdBaggageInfo, .adtBaggageInfo {
          display: flex;

          .chdBaggageInfoT1 {
            width: 149px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }

          .chdBaggageInfoT2 {
            width: 117px;
            line-height: 20px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }
        }

        .chdBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1 {
            line-height: 40px;
          }
        }

        .adtBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1, .chdBaggageInfoT2 {
            line-height: 30px;
          }
        }
      }
    }

    .TicketRemarks {
      width: 100%;
      margin-top: 11px;
      text-align: left;
      line-height: 15px;

      p {
        font-size: 12px;
        color: #666666;
      }
    }

    //退改签info
    .TicketContentInfos {
      padding: 10px;

      p {
        font-size: 13px;
        color: #333333;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
</style>
